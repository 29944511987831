<template>
  <div class="dashboard workout-by-page workout-by-list-page">
    <div class="workout-by-list-heading-wrapper">
      <div class="container">
        <div class="workout-by-list-heading d-flex justify-content-between align-items-center">
          <div class="d-none d-xl-flex justify-content-start align-items-center">
            <button type="button" class="heading-back" @click="$router.go(-1)">
              <chevronLeft/>
            </button>
            <p class="section-title uppercase">{{ pageTitle }}</p>
          </div>
          <div class="workout-by-list-options d-flex align-items-center justify-content-xl-end">
          </div>
        </div>

      </div>
    </div>
    <div class="workout-by-list">
        <div class="workout-by-list-row" v-if="showWorkoutList">
          <div class="workout-slider-block" v-for="(item,index) in workoutItems" :key="index">
            <workoutBySlider :sliderItem="item"/>
          </div>
        </div>
        <div class="container" v-if="!showWorkoutList">
          <p>Sem resultados</p>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

//Components
import workoutBySlider from "@/components/workouts/workout-by-slider-category.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import filtersIcon from "@/components/svg/filters.vue";
import orderIcon from "@/components/svg/order-icon.vue";
import filters from "@/components/filters.vue";
import {EventBus} from "@/event-bus";

export default {
  name: "workout-by-list",
  data() {
    return {
      lang: this.$root.siteLang,
      workoutItems: [],
      showWorkoutList: false,
      pageTitle: this.$route.params.pageTitle,
      filtersCount: '',
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.treinar_por,
    }
  },
  components : {
    workoutBySlider,
    chevronLeft,
    filtersIcon,
    orderIcon,
    filters
  },
  methods : {
    toggleFilters() {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    loadProducts (authKey, parentFilter, duration, level) {
      if ( typeof(this.$route.params.id) !== 'undefined' ) { // Enter here when it has an areaID (if its an equipment/objective/muscle)
        let areaSlugId = this.$route.params.id;
        let areaSlugParts = areaSlugId.split('-');
        let idAreaItem = areaSlugParts[areaSlugParts.length - 1];

        let type = 'cp';

        if (idAreaItem == 6) { //exception for equipments
          type = 'cpc';
        }

        if ( duration == undefined ) {
          duration = '';
        }

        if ( level == undefined ) {
          level = '';
        }

        axios
            .get(process.env.VUE_APP_API_URL + 'areas/'+ idAreaItem +'/items?type='+ type +'&with_category_title=0&with_products=1' + duration + level + '&lang=' + this.lang)
            .then((response) => {
              if (this.$route.params.type == 'extra') { // If it comes from equipment slider / muscular group slider / sport slider
                let data = response.data;
                for ( let i = 0; i < data.length; i++ ) {
                  if ( data[i].products != undefined && data[i].products != '' ) {
                    for (let k = 0; k < data[i].products.length; k++) {
                      this.workoutItems.push(data[i].products[k]);
                    }
                  }
                }
              } else {
                this.workoutItems = response.data
              }
            })
            .catch((e) => {
              this.errors.push(e);
            }).finally((e) => {
          (this.workoutItems.length > 0) ? this.showWorkoutList = true : this.showWorkoutList = false;
        });
      } else { // Enter here to list all workouts
        if ( localStorage.subcategory != undefined && localStorage.subcategory != '' ) {
          let category = localStorage.subcategory;
          let categorySlugParts = category.split('-');
          let categoryID = categorySlugParts[categorySlugParts.length - 1];

          this.pageTitle = categorySlugParts[0];

          let type = 'cp';

          if (categoryID == 6) { //exception for equipments
            type = 'cpc';
          }

          axios
              .get(process.env.VUE_APP_API_URL + 'areas/'+ categoryID +'/items?type='+ type +'&with_category_title=0&with_products=1' + duration + level + '&lang=' + this.lang)
              .then((response) => {
                if (this.$route.params.type == 'extra') { // If it comes from equipment slider / muscular group slider / sport slider
                  let data = response.data;
                  for ( let i = 0; i < data.length; i++ ) {
                    if ( data[i].products != undefined && data[i].products != '' ) {
                      for (let k = 0; k < data[i].products.length; k++) {
                        this.workoutItems.push(data[i].products[k]);
                      }
                    }
                  }
                } else {
                  this.workoutItems = response.data
                }
              })
              .catch((e) => {
                this.errors.push(e);
              }).finally((e) => {
            (this.workoutItems.length > 0) ? this.showWorkoutList = true : this.showWorkoutList = false;
          });
        } else {
          axios
              .get(process.env.VUE_APP_API_URL + 'products?id_category=1&with_category_title=0'+ parentFilter + duration + level +'&lang=' + this.lang,{
                headers: {
                  Authorization: 'Bearer ' + authKey
                }
              })
              .then((response) => {
                this.workoutItems = response.data;
              })
              .catch((e) => {
                this.errors.push(e);
              }).finally((e) => {
            (this.workoutItems.length > 0) ? this.showWorkoutList = true : this.showWorkoutList = false;
          });
        }
      }
    }
  },
  mounted() {
    if ( this.$route.params.id != undefined ) {
      let areaSlugId = this.$route.params.id;

      localStorage.subcategory = areaSlugId;
    }

    let authKey = window.$cookies.get("authKey");
    let parentFilter = '';

    this.loadProducts(authKey, parentFilter);

    EventBus.$on('bus-toggleFilters', () => {
      let element = document.getElementsByClassName("filters-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    });

    EventBus.$on('bus-changeFilters', (activeFiltersCount, duration, level) => {
      let durationFilter = '';
      let levelFilter = '';

      if ( activeFiltersCount > 0 ) {
        this.filtersCount = activeFiltersCount;
      }

      if ( duration != '' && duration != undefined ) {
        durationFilter = '&duration=' + duration;
      }

      if ( level != '' && level != undefined ) {
        levelFilter = '&level=' + level;
      }

      this.loadProducts(authKey, parentFilter, durationFilter, levelFilter);
    });

    EventBus.$on('bus-clearFilters', () => {
      this.filtersCount = 0;

      let duration = '';
      let level = '';

      this.loadProducts(authKey, parentFilter, duration, level);
    });
  },
}
</script>