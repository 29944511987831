<template>
  <div class="workouts-slider-wrapper section-padding" v-if="sliderItem.products">
    <div class="container">
      <div class="workouts-slider-holder">
        <div class="workouts-slider-heading d-flex justify-content-between align-items-center">
          <p class="section-title">{{ sliderItem.title }}</p>
          <div class="d-flex justify-content-end align-items-center">
            <router-link :to="{ name: 'workout-by-list-subcategory', params:{id:$root.getLink(sliderItem), type:'extra', pageTitle: sliderItem.title }}" class="heading-link">Ver todos</router-link>
            <div class="workouts-slider-arrows d-none d-xl-flex align-items-center justify-content-center">
              <button type="button" class="workouts-slider-arrow workouts-slider-prev" @click="prevSlide">
                <chevronLeft/>
              </button>
              <button type="button" class="workouts-slider-arrow workouts-slider-next" @click="nextSlide">
                <chevronLeft/>
              </button>
            </div>
          </div>
        </div>
        <Slick ref="slick" :options="slickOptions" class="workouts-slider">
          <div class="workouts-slider-item" v-for="(item,index) in sliderItem.products" :key="index">
            <workoutItem :workoutItem="item"/>
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import workoutItem from "@/components/workouts/workout-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import axios from 'axios';

export default {
  name: "workouts-slider",
  components : {
    Slick,
    workoutItem,
    chevronLeft
  },
  data() {
    return {
      lang: this.$root.siteLang,
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true,
      }
    }
  },
  props: {
    sliderItem: {
      required: true,
    },
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  }
}
</script>